export const dataDrawer = [
  {
    "descripcion":"Configuración",
    "grupo": "configuracion",
    "icon": "fa-gears",
    "order": 1
  },
  {
    "descripcion":"Usuarios",
    "grupo": "usuarios",
    "icon": "fa-users-gear",
    "order": 2
  },
  {
    "descripcion":"Servicio",
    "grupo": "servicio",
    "icon": "fa-utensils",
    "order": 3
  },
  {
    "descripcion":"Control",
    "grupo": "control",
    "icon": "fa-sack-dollar",
    "order": 4
  },
  {
    "descripcion":"CRM",
    "grupo": "crm",
    "icon": "fa-people-group",
    "order": 5
  },
  {
    "descripcion":"Inventario",
    "grupo": "inventario",
    "icon": "fa-book-open-reader",
    "order": 6
  },
  {
    "descripcion": "Materiales",
    "grupo": "materiales",
    "icon": "fa-boxes-packing",
    "order": 7
  },
  {
    "descripcion": "Bodegas",
    "grupo": "bodegas",
    "icon": "solid fa-tents",
    "order": 8
  },
  {
    "descripcion": "Control de Egresos",
    "grupo": "egresos",
    "icon": "solid fa-box-archive",
    "order": 9
  },
  {
    "descripcion": "Costeo",
    "grupo": "costos",
    "icon": "fa-diagram-project",
    "order": 10
  },
  {
    "descripcion":"KPI's",
    "grupo": "kpis",
    "icon": "fa-chart-pie",
    "order": 11
  },
  {
    "descripcion": "Personas",
    "grupo": "personas",
    "icon": "fa-user-gear",
    "order": 12
  },
  {
    "descripcion": "Analítica",
    "grupo": "analitica",
    "icon": "fa-chart-pie",
    "order": 16
  },
]
